import { animateScroll, scroller, Element as ScrollAnchor } from 'react-scroll';

import styles from './App.module.scss';
import logo from '@/assets/images/logo.svg';
import heroImg from '@/assets/images/hero.png';
import option1 from '@/assets/images/option1.png';
import option2 from '@/assets/images/option2.png';
import option3 from '@/assets/images/option3.png';
import appStoreImg from '@/assets/images/AppStore.png';
import gpImg from '@/assets/images/GooglePlay.png';
import evologo from '@/assets/images/evologo.svg';

const mainContainerId = 'host';

function App() {
  return (
    <section id={mainContainerId} className={styles.host}>
      <header onClick={() => animateScroll.scrollToTop({ delay: 0, smooth: 'linear', containerId: mainContainerId })}>
        <img src={logo} alt="Logo" width={264} height={72} />
      </header>

      <section className={styles.fstSection}>
        <div>
          <h1>
            Простой и быстрый <br /> доступ к питанию в школе
          </h1>
          <p>Для всех: учеников, учителей, родителей</p>

          <button onClick={() => scroller.scrollTo('download', { delay: 0, smooth: 'linear', containerId: mainContainerId, offset: -90 })}>
            Скачать приложение
          </button>
        </div>

        <img className={styles.heroImg} src={heroImg} alt="Hero" />
      </section>

      <section className={styles.scndSection}>
        <div className={styles.options}>
          <h2>Платформа школьное питание позволяет:</h2>

          <ul>
            <li>
              <img src={option1} />
              <p>
                <b>Ученикам:</b> <br />
                просто, быстро и безопасно оплачивать покупки в школьной
              </p>
            </li>
            <li>
              <img src={option2} />
              <p>
                <b>Родителям:</b> <br /> контролировать расходы своих детей и быть в курсе их питания
              </p>
            </li>
            <li>
              <img src={option3} />
              <p>
                <b>Учителям:</b> <br />
                удобно отмечать детей в классе и быстро подавать заявки на питание
              </p>
            </li>
          </ul>
        </div>

        <div className={styles.download}>
          <ScrollAnchor name="download" />
          <h2>Установи приложение для использования возможностей платформы:</h2>

          <ul>
            <li>
              <a href="https://apps.apple.com/ru/app/id1643630050" target="_blank" rel="noreferrer">
                <img src={appStoreImg} />
              </a>
            </li>
            <li>
              <a href="https://play.google.com/store/apps/details?id=ru.evosoft.school.pecherskiy" target="_blank" rel="noreferrer">
                <img src={gpImg} />
              </a>
            </li>
          </ul>
        </div>
      </section>

      <footer>
        <div className={styles.topContent}>
          <div>
            <h2>Информация</h2>

            <ul>
              <li>
                <a target="_blank" href="/contact.pdf" rel="noreferrer">
                  Контактная и правовая информация
                </a>
              </li>
              <li>
                <a target="_blank" href="https://lk.pecherskiy.school/apps/shkolnoePitanie/policy.pdf" rel="noreferrer">
                  Политика конфиденциальности
                </a>
              </li>
              <li>
                <a target="_blank" href="/offer.pdf" rel="noreferrer">
                  Договор оферты
                </a>
              </li>
            </ul>

            <p>ИП Печёрский Юрий Андреевич, 663600, Красноярский край, г. Канск, ул. Рабочая, д. 35, кв. 2</p>
          </div>

          <div className={styles.topRightContent}>
            <div>
              <p>+7 (923) 371 50 20</p>
              <p>79233715020@mail.ru</p>
            </div>
            <img src={evologo} />
          </div>
        </div>

        <div className={styles.divider} />

        <p className={styles.license}>© {new Date().getFullYear()} Школьное питание. Все права защищены.</p>
      </footer>
    </section>
  );
}

export default App;
